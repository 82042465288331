import { MessageMap } from 'deliver-portal-commons';

const es: MessageMap = {
  Hive_CountriesLabel: {
    AD: 'Andorra',
    AE: 'Emiratos Árabes Unidos',
    AF: 'Afganistán',
    AG: 'Antigua y Barbuda',
    AI: 'Anguila',
    AL: 'Albania',
    AM: 'Armenia',
    AO: 'Angola',
    AQ: 'Antártida',
    AR: 'Argentina',
    AS: 'Samoa Americana',
    AT: 'Austria',
    AU: 'Australia',
    AW: 'Aruba',
    AZ: 'Azerbaiyán',
    BA: 'Bosnia y Herzegovina',
    BB: 'Barbados',
    BD: 'Bangladés',
    BE: 'Bélgica',
    BF: 'Burkina Faso',
    BG: 'Bulgaria',
    BH: 'Baréin',
    BI: 'Burundi',
    BJ: 'Benín',
    BL: 'San Bartolomé',
    BM: 'Bermudas',
    BN: 'Brunéi',
    BO: 'Bolivia',
    BR: 'Brasil',
    BS: 'Bahamas',
    BT: 'Bután',
    BW: 'Botsuana',
    BY: 'Bielorrusia',
    BZ: 'Belice',
    CA: 'Canadá',
    CC: 'Islas Cocos',
    CD: 'República Democrática del Congo',
    CF: 'República Centroafricana',
    CG: 'República del Congo',
    CH: 'Suiza',
    CI: 'Costa de Marfil',
    CL: 'Chile',
    CM: 'Camerún',
    CN: 'China',
    CO: 'Colombia',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cabo Verde',
    CW: 'Curazao',
    CX: 'Isla de Navidad',
    CY: 'Chipre',
    CZ: 'República Checa',
    DE: 'Alemania',
    DJ: 'Yibuti',
    DK: 'Dinamarca',
    DM: 'Dominica',
    DO: 'República Dominicana',
    DZ: 'Argelia',
    EC: 'Ecuador',
    EE: 'Estonia',
    EG: 'Egipto',
    EH: 'Sahara Occidental',
    ER: 'Eritrea',
    ES: 'España',
    ET: 'Etiopía',
    FI: 'Finlandia',
    FJ: 'Fiyi',
    FM: 'Micronesia',
    FO: 'Islas Feroe',
    FR: 'Francia',
    GA: 'Gabón',
    GB: 'Reino Unido',
    GD: 'Granada',
    GE: 'Georgia',
    GF: 'Guayana Francesa',
    GG: 'Guernsey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Groenlandia',
    GM: 'Gambia',
    GN: 'Guinea',
    GP: 'Guadalupe',
    GQ: 'Guinea Ecuatorial',
    GR: 'Grecia',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinea-Bisáu',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HN: 'Honduras',
    HR: 'Croacia',
    HT: 'Haití',
    HU: 'Hungría',
    ID: 'Indonesia',
    IE: 'Irlanda',
    IL: 'Israel',
    IM: 'Isla de Man',
    IN: 'India',
    IO: 'Territorio Británico del Océano Índico',
    IQ: 'Irak',
    IR: 'Irán',
    IS: 'Islandia',
    IT: 'Italia',
    JE: 'Jersey',
    JM: 'Jamaica',
    JO: 'Jordania',
    JP: 'Japón',
    KE: 'Kenia',
    KG: 'Kirguistán',
    KH: 'Camboya',
    KI: 'Kiribati',
    KM: 'Comoras',
    KN: 'San Cristóbal y Nieves',
    KP: 'Corea del Norte',
    KR: 'Corea del Sur',
    KW: 'Kuwait',
    KY: 'Islas Caimán',
    KZ: 'Kazajistán',
    LA: 'Laos',
    LB: 'Líbano',
    LC: 'Santa Lucía',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Liberia',
    LS: 'Lesoto',
    LT: 'Lituania',
    LU: 'Luxemburgo',
    LV: 'Letonia',
    LY: 'Libia',
    MA: 'Marruecos',
    MC: 'Mónaco',
    MD: 'Moldavia',
    ME: 'Montenegro',
    MF: 'San Martín',
    MG: 'Madagascar',
    MH: 'Islas Marshall',
    MK: 'Macedonia del Norte',
    ML: 'Malí',
    MM: 'Birmania',
    MN: 'Mongolia',
    MO: 'Macao',
    MP: 'Islas Marianas del Norte',
    MQ: 'Martinica',
    MR: 'Mauritania',
    MS: 'Montserrat',
    MT: 'Malta',
    MU: 'Mauricio',
    MV: 'Maldivas',
    MW: 'Malaui',
    MX: 'México',
    MY: 'Malasia',
    MZ: 'Mozambique',
    NA: 'Namibia',
    NC: 'Nueva Caledonia',
    NE: 'Níger',
    NF: 'Isla Norfolk',
    NG: 'Nigeria',
    NI: 'Nicaragua',
    NL: 'Países Bajos',
    NO: 'Noruega',
    NP: 'Nepal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Nueva Zelanda',
    OM: 'Omán',
    PA: 'Panamá',
    PE: 'Perú',
    PF: 'Polinesia Francesa',
    PG: 'Papúa Nueva Guinea',
    PH: 'Filipinas',
    PK: 'Pakistán',
    PL: 'Polonia',
    PM: 'San Pedro y Miquelón',
    PN: 'Islas Pitcairn',
    PR: 'Puerto Rico',
    PS: 'Palestina',
    PT: 'Portugal',
    PW: 'Palaos',
    PY: 'Paraguay',
    QA: 'Catar',
    RE: 'Reunión',
    RO: 'Rumanía',
    RS: 'Serbia',
    RU: 'Rusia',
    RW: 'Ruanda',
    SA: 'Arabia Saudita',
    SB: 'Islas Salomón',
    SC: 'Seychelles',
    SD: 'Sudán',
    SE: 'Suecia',
    SG: 'Singapur',
    SH: 'Santa Elena',
    SI: 'Eslovenia',
    SJ: 'Svalbard y Jan Mayen',
    SK: 'Eslovaquia',
    SL: 'Sierra Leona',
    SM: 'San Marino',
    SN: 'Senegal',
    SO: 'Somalia',
    SR: 'Surinam',
    SS: 'Sudán del Sur',
    ST: 'Santo Tomé y Príncipe',
    SV: 'El Salvador',
    SX: 'Sint Maarten',
    SY: 'Siria',
    SZ: 'Suazilandia',
    TC: 'Islas Turcas y Caicos',
    TD: 'Chad',
    TF: 'Territorios Australes Franceses',
    TG: 'Togo',
    TH: 'Tailandia',
    TJ: 'Tayikistán',
    TK: 'Tokelau',
    TL: 'Timor Oriental',
    TM: 'Turkmenistán',
    TN: 'Túnez',
    TO: 'Tonga',
    TR: 'Turquía',
    TT: 'Trinidad y Tobago',
    TV: 'Tuvalu',
    TZ: 'Tanzania',
    UA: 'Ucrania',
    UG: 'Uganda',
    US: 'Estados Unidos',
    UY: 'Uruguay',
    UZ: 'Uzbekistán',
    VA: 'Ciudad del Vaticano',
    VC: 'San Vicente y las Granadinas',
    VE: 'Venezuela',
    VG: 'Islas Vírgenes Británicas',
    VI: 'Islas Vírgenes de los Estados Unidos',
    VN: 'Vietnam',
    VU: 'Vanuatu',
    WF: 'Wallis y Futuna',
    WS: 'Samoa',
    YE: 'Yemen',
    YT: 'Mayotte',
    ZA: 'Sudáfrica',
    ZM: 'Zambia',
    ZW: 'Zimbabue',
  },
  Hive_DriverPortfolioPage: {
    TITLE: 'Conductores y gestión de cartera',
    SIDEBAR_TITLE: 'Gestión de conductores',
    SEARCH_DRIVER: 'Buscar por nombre de conductor, ID de conductor o patente',
    REGISTER_DRIVERS: 'Registro de conductores en Data management',
    NO_DRIVERS_REGISTERED: 'No hay conductores registrados',
    ONCE_REGISTERED:
      'Una vez registrados, los conductores aparecerán en esta sección',
    REGISTER_DRIVERS_SHORT: 'Registrar controladores',
    DRIVER_ID: 'ID',
    DRIVER_NAME: 'Nombre',
    VEHICLE: 'Camión',
    POCS: 'PDVs en cartera',
    ACTIONS: 'Acciones',
    ERROR_MESSAGE:
      'No se ha podido cargar la lista de conductores. Vuelva a intentarlo más tarde.',
  },
  Hive_DriverProfilePage: {
    DRIVER_DETAILS: 'Detalles del conductor',
    EDIT_BUTTON: 'Editar',
    CANCEL_BUTTON: 'Cancelar',
    SAVE_CHANGES_BUTTON: 'Guardar cambios',
    DRIVER_NAME: 'Nombre',
    DRIVER_ID: 'ID',
    DRIVER_TRUCK: 'Camión',
    DRIVER_PHONE: 'Teléfono',
    CUSTOMER_PORTFOLIO_TITLE: 'Cartera de clientes',
    TABLE_SEARCH_PLACEHOLDER: 'Buscar por nombre o ID de PDV',
    TABLE_COLUMN_POC_ID: 'ID PDV',
    TABLE_COLUMN_POC_NAME: 'Nombre del PDV',
    TABLE_COLUMN_ADDRESS: 'Dirección',
    TABLE_EMPTY_NO_POC_REGISTERED:
      'Una vez registrado, los PDV aparecerán en esta sección.',
    TABLE_EMPTY_NO_POC_FOUND:
      'No se encontraron resultados. Revise la ortografía o pruebe con diferentes palabras clave.',
    TABLE_SELECTED_ONE_ITEM: 'PDV Seleccionado',
    TABLE_SELECTED_ITEMS: 'PDVs Seleccionados',
    TABLE_CLEAR_SELECTION: 'Borrar selección',
    SEARCH_DRIVER: 'Buscar conductor',
    REASSIGN_POCS: 'Reasignar PDV',
    CONFIRM_REASSIGN_MODAL_TITLE: '¿Reasignar PDV?',
    CONFIRM_REASSIGN_MODAL_MESSAGE:
      'Los PDV seleccionados se reasignarán al conductor seleccionado.',
    CONFIRM_REASSIGN_MODAL_SAVE_CHANGES_BUTTON: 'Guardar cambios',
    CONFIRM_REASSIGN_MODAL_CANCEL_BUTTON: 'Volver atrás',
    CONFIRM_REASSIGN_MODAL_CONFIRM_BUTTON: 'Reasignar',
    DELETE_POC_MODAL_TITLE: '¿Eliminar PDV?',
    DELETE_POC_MODAL_MESSAGE:
      'Esto eliminará permanentemente la PDV. Esto no se puede deshacer.',
    DELETE_POC_MODAL_CONFIRM_BUTTON: 'Eliminar PDV',
    DELETE_POC_MODAL_CANCEL_BUTTON: 'Volver atrás',
    DISCARD_CHANGES_TITLE: '¿Descartar cambios?',
    DISCARD_CHANGES_DESCRIPTION:
      'Se descartarán todos los cambios no guardados.',
    DISCARD_CHANGES_CONFIRMATION: 'Descartar cambios',
    DISCARD_CHANGES_GO_BACK: 'Volver atrás',
    ERROR_LOAD_MESSAGE:
      'Error al cargar los detalles del conductor. Vuelva a intentarlo más tarde.',
    ERROR_UPDATE_MESSAGE:
      'Error al actualizar la información del conductor. Vuelva a intentarlo más tarde.',
    ERROR_REASSIGN_MESSAGE:
      'Error al reasignar PDV. Vuelva a intentarlo más tarde.',
    SUCCESS_UPDATE_MESSAGE:
      'Información del conductor actualizada correctamente',
    SUCCESS_REASSIGN_MESSAGE: `{amount, plural,
      one {POC reasignado con éxito}
      other {POCs reasignados con éxito}
    }`,
    SUCCESS_REMOVE_POC_MESSAGE: `{amount, plural,
      one {POC eliminado con éxito de la cartera de conductores}
      other {POCs eliminados con éxito de la cartera de conductores}
    }`,
  },
  Hive_RoutesPage: {
    TITLE: 'Rutas',
    SIDEBAR_TITLE: 'Rutas',
    CREATE_ROUTES: 'Crear rutas',
    SCHEDULED_TAB: 'Programadas',
    DRAFTS_TAB: 'Borradores',
    SCHEDULED_SEARCH: 'Buscar por ID de ruta, conductor o camión',
    DRAFTS_SEARCH: 'Buscar por usuario o ID de borrador',
    SELECT_DATE: 'Seleccionar fecha',
    DATES_SELECTED: '{startDate} al {endDate}',
    YESTERDAY: 'Ayer',
    TODAY: 'Hoy',
    TOMORROW: 'Mañana',
    ROUTE_ID: 'ID de ruta',
    DATE: 'Fecha',
    DRIVER: 'Conductor',
    TRUCK: 'Camión',
    VISITS: 'Visitas',
    EMPTY_ROUTES_TITLE: 'No se han creado rutas',
    EMPTY_DRAFTS_TITLE: 'No se han creado borradores',
    EMPTY_ROUTES_DESCRIPTION:
      'Cualquier ruta creada en la herramienta de planificación de rutas aparecerá en esta sección',
    EMPTY_DRATS_DESCRIPTION:
      'Cualquier ruta creada en la herramienta de planificación de rutas aparecerá en esta sección',
    EMPTY_ROUTES_BUTTON: 'Crear rutas',
    EDIT_DRAFT: 'Editar borrador',
    DELETE_DRAFT: 'Eliminar borrador',
    DRAFT_ID: 'ID de borrador',
    CREATED_BY: 'Creado por',
    CREATED_ON: 'Creado el',
    ACTIONS: 'Acciones',
  },
  Hive_CreateRoutesPage: {
    TITLE: 'Crear rutas',
    MAP_LEGEND: 'PDVs sin camión ni conductor',
    MAP_MARKERS_UNASSIGNED: 'Visita no asignada',
    TOAST_ERROR_MESSAGE_DDC:
      'No se pueden recuperar datos de ubicación del centro de distribución.',
  },
  HIVE_ProgressStepper: {
    ROUTE_SETUP: 'Configuración de ruta',
    ORGANIZE_ROUTES: 'Organizar rutas',
    ROUTE_SUMMARY: 'Resumen de ruta',
  },
  Hive_OrganizeRouteStep: {
    ROUTES: 'rutas',
    TO: 'A',
    CHECKBOX_SELECT_ALL: 'Seleccionar todo',
    SELECT_DRIVER_LABEL: 'Seleccionar conductor',
    SELECT_TRUCK_LABEL: 'Seleccionar camión',
    UNASSIGNED_VISITS: 'Visitas no asignadas',
    UNASSIGNED_LABEL: 'No asignado',
    ASSIGNED_LABEL: 'Asignado',
    SUCCESS_REASSIGN_VISITS_MESSAGE: `{amount, plural,
      one {# visita asignada exitosamente.}
      other {# visitas asignadas exitosamente.}
    }`,
    ERROR_REASSIGN_VISITS_MESSAGE:
      'No se pueden asignar visitas. Intentar otra vez.',
    SUCCESS_REMOVE_VISITS_MESSAGE: `{amount, plural,
      one {# visita eliminada exitosamente.}
      other {# visitas eliminada exitosamente.}
    }`,
    ERROR_REMOVE_VISITS_MESSAGE:
      'Error al eliminar visitas. Intentar otra vez.',
    SEARCH_DRIVER_OR_TRUCK: 'Búsqueda por conductor o camión',
  },
  HIVE_GenericSelector: {
    EMPTY_AVAILABLE_DRIVERS:
      'No hay resultados. Pruebe con el nombre/ID del conductor o el camión.',
  },
  HIVE_RemovePocDialog: {
    TITLE: '¿Quitar visitas?',
    MESSAGE:
      'Las visitas seleccionadas serán eliminadas de la planificación de rutas. ¿Quieres continuar?',
    CANCEL_BUTTON: 'Volver',
    CONFIRM_BUTTON: 'Quitar visitas',
  },
  HIVE_RouteSearchBar: {
    SEARCH_POC: 'Buscar por PDV o camión',
  },
  HIVE_AddOrderDisplay: {
    ADD_ORDERS: 'Agregar pedidos para crear rutas',
    ADD_ORDERS_BUTTON: 'Agregar pedidos',
  },
  HIVE_OrganizeRoutesStepFooter: {
    GO_BACK: 'Volver',
    CONTINUE: 'Continuar',
    ASSIGN: 'Asignar',
  },
  HIVE_RouteSetupFooter: {
    CONTINUE: 'Continuar',
  },
  HIVE_RouteSetupStep: {
    SELECT_DATE: 'Seleccionar fecha',
    ROUTE_DATE: 'Fecha de la ruta',
    ROUTE_DATE_SUBTITLE: '¿Cuándo se realizará la ruta?',
  },
  Hive_OrdersModal: {
    TITLE: 'Seleccionar pedidos',
    ORDER_ID: 'ID pedido',
    POC_ID: 'ID PDV',
    SEARCH_BY_POC_ID: 'Buscar por ID PDV',
    SEARCH_BY_ORDER_ID: 'Buscar por ID pedido',
    SELECT_ALL: 'Seleccionar todo',
    SELECT_DATE: 'Seleccionar fecha',
    DATES_SELECTED: '{startDate} al {endDate}',
    ITEMS: 'artículos',
    POC_ID_CARD: 'PDV ID: {pocID}',
    CONFIRM_SELECTION: 'Confirmar selección',
    SELECTION_LIST: 'Lista de selección',
    REMOVE_ALL: 'Eliminar todo',
    ORDERS_SELECTED: `{amount, plural, one {# pedido seleccionado} other {# pedidos seleccionados}}`,
    EMPTY_SELECTION_LIST: 'Los pedidos seleccionados se mostrarán en la lista.',
    CANCEL_SELECTION: 'Cancelar selección',
    NO_ORDERS_FOUND:
      'No se encontraron pedidos. Intenta con diferentes palabras clave o fechas.',
    SHOWING_RESULTS:
      'Mostrando {results, plural, one {# resultado} other {# resultados}} para "{search}"',
    NO_AVAILABLE_ORDERS:
      'No hay pedidos disponibles. Seleccione nuevos pedidos para continuar.',
    SOME_UNAVAILABLE_ORDERS:
      'Algunas de las órdenes seleccionadas no están disponibles.',
    ORDERS_SUCCESSFULLY_ADDED: 'Pedidos añadidos con éxito.',
    LOAD_ORDERS_ERROR: 'No se pueden cargar los pedidos. Inténtalo de nuevo.',
  },
  HIVE_ChangeDateConfirmationModal: {
    TITLE: '¿Cambiar fecha de la ruta?',
    DESCRIPTION:
      'Si cambia la fecha de la ruta, se eliminarán todas las visitas y los pedidos asignados.',
    CANCEL: 'Cancelar',
    CONFIRM: 'Confirmar',
  },
};

export default {
  messages: es,
  language: 'es',
};
