import { MessageMap } from 'deliver-portal-commons';

const fr: MessageMap = {
  Hive_CountriesLabel: {
    AD: 'Andorre',
    AE: 'Émirats arabes unis',
    AF: 'Afghanistan',
    AG: 'Antigua-et-Barbuda',
    AI: 'Anguilla',
    AL: 'Albanie',
    AM: 'Arménie',
    AO: 'Angola',
    AQ: 'Antarctique',
    AR: 'Argentine',
    AS: 'Samoa américaines',
    AT: 'Autriche',
    AU: 'Australie',
    AW: 'Aruba',
    AZ: 'Azerbaïdjan',
    BA: 'Bosnie-Herzégovine',
    BB: 'Barbade',
    BD: 'Bangladesh',
    BE: 'Belgique',
    BF: 'Burkina Faso',
    BG: 'Bulgarie',
    BH: 'Bahreïn',
    BI: 'Burundi',
    BJ: 'Bénin',
    BL: 'Saint-Barthélemy',
    BM: 'Bermudes',
    BN: 'Brunei',
    BO: 'Bolivie',
    BR: 'Brésil',
    BS: 'Bahamas',
    BT: 'Bhoutan',
    BW: 'Botswana',
    BY: 'Biélorussie',
    BZ: 'Belize',
    CA: 'Canada',
    CC: 'Îles Cocos',
    CD: 'République démocratique du Congo',
    CF: 'République centrafricaine',
    CG: 'République du Congo',
    CH: 'Suisse',
    CI: "Côte d'Ivoire",
    CL: 'Chili',
    CM: 'Cameroun',
    CN: 'Chine',
    CO: 'Colombie',
    CR: 'Costa Rica',
    CU: 'Cuba',
    CV: 'Cap-Vert',
    CW: 'Curaçao',
    CX: 'Île Christmas',
    CY: 'Chypre',
    CZ: 'République tchèque',
    DE: 'Allemagne',
    DJ: 'Djibouti',
    DK: 'Danemark',
    DM: 'Dominique',
    DO: 'République dominicaine',
    DZ: 'Algérie',
    EC: 'Équateur',
    EE: 'Estonie',
    EG: 'Égypte',
    EH: 'Sahara occidental',
    ER: 'Érythrée',
    ES: 'Espagne',
    ET: 'Éthiopie',
    FI: 'Finlande',
    FJ: 'Fidji',
    FM: 'Micronésie',
    FO: 'Îles Féroé',
    FR: 'France',
    GA: 'Gabon',
    GB: 'Royaume-Uni',
    GD: 'Grenade',
    GE: 'Géorgie',
    GF: 'Guyane française',
    GG: 'Guernesey',
    GH: 'Ghana',
    GI: 'Gibraltar',
    GL: 'Groenland',
    GM: 'Gambie',
    GN: 'Guinée',
    GP: 'Guadeloupe',
    GQ: 'Guinée équatoriale',
    GR: 'Grèce',
    GT: 'Guatemala',
    GU: 'Guam',
    GW: 'Guinée-Bissau',
    GY: 'Guyana',
    HK: 'Hong Kong',
    HN: 'Honduras',
    HR: 'Croatie',
    HT: 'Haïti',
    HU: 'Hongrie',
    ID: 'Indonésie',
    IE: 'Irlande',
    IL: 'Israël',
    IM: 'Île de Man',
    IN: 'Inde',
    IO: "Territoire britannique de l'océan Indien",
    IQ: 'Irak',
    IR: 'Iran',
    IS: 'Islande',
    IT: 'Italie',
    JE: 'Jersey',
    JM: 'Jamaïque',
    JO: 'Jordanie',
    JP: 'Japon',
    KE: 'Kenya',
    KG: 'Kirghizistan',
    KH: 'Cambodge',
    KI: 'Kiribati',
    KM: 'Comores',
    KN: 'Saint-Christophe-et-Niévès',
    KP: 'Corée du Nord',
    KR: 'Corée du Sud',
    KW: 'Koweït',
    KY: 'Îles Caïmans',
    KZ: 'Kazakhstan',
    LA: 'Laos',
    LB: 'Liban',
    LC: 'Sainte-Lucie',
    LI: 'Liechtenstein',
    LK: 'Sri Lanka',
    LR: 'Libéria',
    LS: 'Lesotho',
    LT: 'Lituanie',
    LU: 'Luxembourg',
    LV: 'Lettonie',
    LY: 'Libye',
    MA: 'Maroc',
    MC: 'Monaco',
    MD: 'Moldavie',
    ME: 'Monténégro',
    MF: 'Saint-Martin',
    MG: 'Madagascar',
    MH: 'Îles Marshall',
    MK: 'Macédoine du Nord',
    ML: 'Mali',
    MM: 'Myanmar',
    MN: 'Mongolie',
    MO: 'Macao',
    MP: 'Îles Mariannes du Nord',
    MQ: 'Martinique',
    MR: 'Mauritanie',
    MS: 'Montserrat',
    MT: 'Malte',
    MU: 'Maurice',
    MV: 'Maldives',
    MW: 'Malawi',
    MX: 'Mexique',
    MY: 'Malaisie',
    MZ: 'Mozambique',
    NA: 'Namibie',
    NC: 'Nouvelle-Calédonie',
    NE: 'Niger',
    NF: 'Île Norfolk',
    NG: 'Nigéria',
    NI: 'Nicaragua',
    NL: 'Pays-Bas',
    NO: 'Norvège',
    NP: 'Népal',
    NR: 'Nauru',
    NU: 'Niue',
    NZ: 'Nouvelle-Zélande',
    OM: 'Oman',
    PA: 'Panama',
    PE: 'Pérou',
    PF: 'Polynésie française',
    PG: 'Papouasie-Nouvelle-Guinée',
    PH: 'Philippines',
    PK: 'Pakistan',
    PL: 'Pologne',
    PM: 'Saint-Pierre-et-Miquelon',
    PN: 'Îles Pitcairn',
    PR: 'Porto Rico',
    PT: 'Portugal',
    PW: 'Palaos',
    PY: 'Paraguay',
    QA: 'Qatar',
    RE: 'La Réunion',
    RO: 'Roumanie',
    RS: 'Serbie',
    RU: 'Russie',
    RW: 'Rwanda',
    SA: 'Arabie saoudite',
    SB: 'Îles Salomon',
    SC: 'Seychelles',
    SD: 'Soudan',
    SE: 'Suède',
    SG: 'Singapour',
    SH: 'Sainte-Hélène',
    SI: 'Slovénie',
    SJ: 'Svalbard et Jan Mayen',
    SK: 'Slovaquie',
    SL: 'Sierra Leone',
    SM: 'Saint-Marin',
    SN: 'Sénégal',
    SO: 'Somalie',
    SR: 'Suriname',
    SS: 'Soudan du Sud',
    ST: 'Sao Tomé-et-Principe',
    SV: 'Salvador',
    SX: 'Saint-Martin',
    SY: 'Syrie',
    SZ: 'Eswatini',
    TC: 'Îles Turques-et-Caïques',
    TD: 'Tchad',
    TF: 'Terres australes françaises',
    TG: 'Togo',
    TH: 'Thaïlande',
    TJ: 'Tadjikistan',
    TK: 'Tokelau',
    TL: 'Timor-Leste',
    TM: 'Turkménistan',
    TN: 'Tunisie',
    TO: 'Tonga',
    TR: 'Turquie',
    TT: 'Trinité-et-Tobago',
    TV: 'Tuvalu',
    TZ: 'Tanzanie',
    UA: 'Ukraine',
    UG: 'Ouganda',
    US: 'États-Unis',
    UY: 'Uruguay',
    UZ: 'Ouzbékistan',
    VA: 'Cité du Vatican',
    VC: 'Saint-Vincent-et-les-Grenadines',
    VE: 'Venezuela',
    VG: 'Îles Vierges britanniques',
    VI: 'Îles Vierges américaines',
    VN: 'Viêt Nam',
    VU: 'Vanuatu',
    WF: 'Wallis-et-Futuna',
    WS: 'Samoa',
    YE: 'Yémen',
    YT: 'Mayotte',
    ZA: 'Afrique du Sud',
    ZM: 'Zambie',
    ZW: 'Zimbabwe',
  },
  Hive_DriverPortfolioPage: {
    TITLE: 'Conducteurs et gestion de portefeuille',
    SIDEBAR_TITLE: 'Gestion de conducteurs',
    SEARCH_DRIVER: 'Recherche par conducteur, identifiant ou plaque.',
    REGISTER_DRIVERS: 'Enregistrer des conducteurs sur Data management',
    NO_DRIVERS_REGISTERED: 'Aucun conducteur enregistré',
    ONCE_REGISTERED:
      'Une fois enregistrés, les pilotes apparaîtront dans cette section',
    REGISTER_DRIVERS_SHORT: 'Enregistrer les conducteurs',
    DRIVER_ID: 'ID',
    DRIVER_NAME: 'Nom',
    VEHICLE: 'Camion',
    POCS: 'PDCs dans le portefeuille',
    ACTIONS: 'Actions',
    ERROR_MESSAGE:
      'Échec du chargement de la liste des pilotes. Réessayez plus tard.',
  },
  Hive_DriverProfilePage: {
    DRIVER_DETAILS: 'Détails du chauffeur',
    EDIT_BUTTON: 'Modifier',
    CANCEL_BUTTON: 'Annuler',
    SAVE_CHANGES_BUTTON: 'Enregistrer les modifications',
    DRIVER_NAME: 'Nom',
    DRIVER_ID: 'ID',
    DRIVER_TRUCK: 'Camion',
    DRIVER_PHONE: 'Téléphone',
    CUSTOMER_PORTFOLIO_TITLE: 'Portefeuille de clients',
    TABLE_SEARCH_PLACEHOLDER: 'Recherche par nom ou ID de PDC',
    TABLE_COLUMN_POC_ID: 'ID PDC',
    TABLE_COLUMN_POC_NAME: 'Nom du PDC',
    TABLE_COLUMN_ADDRESS: 'Adresse',
    TABLE_EMPTY_NO_POC_REGISTERED:
      'Une fois enregistrés, les pilotes apparaîtront dans cette section',
    TABLE_EMPTY_NO_POC_FOUND:
      'Aucun PDC trouvé. Veuillez réessayer avec un autre terme de recherche.',
    TABLE_SELECTED_ONE_ITEM: 'PDC Sélectionné',
    TABLE_SELECTED_ITEMS: 'PDCs Sélectionnés',
    TABLE_CLEAR_SELECTION: 'Effacer la sélection',
    SEARCH_DRIVER: 'Rechercher un conducteur',
    REASSIGN_POCS: 'Réaffecter les PDC',
    CONFIRM_REASSIGN_MODAL_TITLE: 'Réaffecter les PDC?',
    CONFIRM_REASSIGN_MODAL_MESSAGE:
      'Les PDC sélectionnés seront réattribués au conducteur sélectionné.',
    CONFIRM_REASSIGN_MODAL_SAVE_CHANGES_BUTTON: 'Enregistrer les modifications',
    CONFIRM_REASSIGN_MODAL_CANCEL_BUTTON: 'Revenir en arrière',
    CONFIRM_REASSIGN_MODAL_CONFIRM_BUTTON: 'Réassigner',
    DELETE_POC_MODAL_TITLE: 'Supprimer PDC?',
    DELETE_POC_MODAL_MESSAGE:
      'Le PDC sélectionné sera supprimé du portefeuille du conducteur.',
    DELETE_POC_MODAL_CONFIRM_BUTTON: 'Supprimer PDC',
    DELETE_POC_MODAL_CANCEL_BUTTON: 'Revenir en arrière',
    DISCARD_CHANGES_TITLE: 'Annuler les modifications?',
    DISCARD_CHANGES_DESCRIPTION:
      'Toutes les modifications non enregistrées seront supprimées.',
    DISCARD_CHANGES_CONFIRMATION: 'Abandonner les modifications',
    DISCARD_CHANGES_GO_BACK: 'Revenir en arrière',
    ERROR_LOAD_MESSAGE:
      'Échec du chargement des détails du pilote. Réessayez plus tard.',
    ERROR_UPDATE_MESSAGE:
      'Échec de la mise à jour des informations sur le pilote. Réessayer plus tard.',
    ERROR_REASSIGN_MESSAGE:
      'Échec de la réaffectation du POC. Réessayer plus tard.',
    SUCCESS_UPDATE_MESSAGE:
      'Les informations sur le conducteur ont été mises à jour avec succès',
    SUCCESS_REASSIGN_MESSAGE: 'Réaffectation réussie du POC',
    SUCCESS_REMOVE_POC_MESSAGE:
      'Le POC a été supprimé avec succès du portefeuille des conducteurs',
  },
  Hive_RoutesPage: {
    TITLE: 'Itinéraires',
    SIDEBAR_TITLE: 'Itinéraires',
    CREATE_ROUTES: 'Créer des itinéraires',
    SCHEDULED_TAB: 'Programmé',
    DRAFTS_TAB: 'Brouillons',
    SCHEDULED_SEARCH: "Recherche par ID d'itinéraire, conducteur ou camion",
    DRAFTS_SEARCH: 'Rechercher par utilisateur ou ID de brouillon',
    SELECT_DATE: 'Sélectionnez une date',
    DATES_SELECTED: '{startDate} a {endDate}',
    YESTERDAY: 'Hier',
    TODAY: "Aujourd'hui",
    TOMORROW: 'Demain',
    ROUTE_ID: "ID d'itinéraire",
    DATE: 'Date',
    DRIVER: 'Conducteur',
    TRUCK: 'Camion',
    VISITS: 'Visites',
    EMPTY_ROUTES_TITLE: 'Aucun itinéraire créé',
    EMPTY_DRAFTS_TITLE: 'Aucun brouillon créé',
    EMPTY_ROUTES_DESCRIPTION:
      "Tous les itinéraires créés sur l'outil de planification d'itinéraire apparaîtront dans cette section.",
    EMPTY_DRAFTS_DESCRIPTION:
      "Tous les brouillons créés sur l'outil de planification d'itinéraire apparaîtront dans cette section.",
    EMPTY_ROUTES_BUTTON: 'Créer des itinéraires',
    EDIT_DRAFT: 'Modifier le brouillon',
    DELETE_DRAFT: 'Supprimer le brouillon',
    DRAFT_ID: "Brouillon d'identification",
    CREATED_BY: 'Créé par',
    CREATED_ON: 'Créé le',
    ACTIONS: 'Actes',
  },
  Hive_CreateRoutesPage: {
    TITLE: 'Créer des itinéraires',
    MAP_LEGEND: 'POC sans camion ni chauffeur',
    MAP_MARKERS_UNASSIGNED: 'Visite non attribuée',
    TOAST_ERROR_MESSAGE_DDC:
      'Impossible de récupérer les données de localisation du centre de distribution.',
  },
  HIVE_ProgressStepper: {
    ROUTE_SETUP: "Configuration de l'itinéraire",
    ORGANIZE_ROUTES: 'Organiser des itinéraires',
    ROUTE_SUMMARY: "Résumé de l'itinéraire",
  },
  Hive_OrganizeRouteStep: {
    ROUTES: 'itinéraires',
    TO: 'À',
    CHECKBOX_SELECT_ALL: 'Tout sélectionner',
    SELECT_DRIVER_LABEL: 'Sélectionnez le pilote',
    SELECT_TRUCK_LABEL: 'Sélectionnez le camion',
    UNASSIGNED_VISITS: 'Visites non attribuées',
    UNASSIGNED_LABEL: 'Non attribué',
    ASSIGNED_LABEL: 'Attribué',
    SUCCESS_REASSIGN_VISITS_MESSAGE: `{amount, plural,
      one {# visite attribuée avec succès.}
      other {# visites attribuées avec succès.}
    }`,
    ERROR_REASSIGN_VISITS_MESSAGE: `Impossible d'attribuer des visites. Essayer à nouveau.`,
    SUCCESS_REMOVE_VISITS_MESSAGE: `{amount, plural,
      one {# visite supprimée avec succès.}
      other {# visites supprimées avec succès.}
    }`,
    ERROR_REMOVE_VISITS_MESSAGE: `Erreur lors de la suppression des visites. Essayer à nouveau.`,
    SEARCH_DRIVER_OR_TRUCK: 'Recherche par chauffeur ou par camion',
  },
  HIVE_GenericSelector: {
    EMPTY_SEARCH_MESSAGE:
      'Aucun résultat. Essayez le nom/identifiant du conducteur ou le camion.',
  },
  HIVE_RouteSearchBar: {
    SEARCH_POC: 'Recherche par POC ou camion',
  },
  HIVE_AddOrderDisplay: {
    ADD_ORDERS: 'Ajouter des commandes pour créer des itinéraires',
    ADD_ORDERS_BUTTON: 'Ajouter des commandes',
  },
  HIVE_OrganizeRoutesStepFooter: {
    GO_BACK: 'Retourner',
    CONTINUE: 'Continuer',
    ASSIGN: 'Attribuer',
  },
  HIVE_RouteSetupFooter: {
    CONTINUE: 'Continuer',
  },
  HIVE_RouteSetupStep: {
    SELECT_DATE: 'Sélectionnez une date',
    ROUTES_DATE: "Date de l'itinéraire",
    ROUTES_SUBTITLE: 'Quand les itinéraires seront-ils exécutés ?',
  },
  Hive_OrdersModal: {
    ORDER_ID: 'ID commande',
    POC_ID: 'ID PDC',
    SEARCH_BY_POC_ID: 'Recherche par ID PDC',
    SEARCH_BY_ORDER_ID: 'Recherche par ID commande',
    SELECT_ALL: 'Sélectionner tout',
    TITLE: 'Ajouter des commandes',
    SELECT_DATE: 'Sélectionnez une date',
    DATES_SELECTED: '{startDate} a {endDate}',
    ITEMS: 'articles',
    POC_ID_CARD: 'PDC ID: {pocID}',
    CONFIRM_SELECTION: 'Confirmer la sélection',
    SELECTION_LIST: 'Liste de sélection',
    REMOVE_ALL: 'Tout supprimer',
    ORDERS_SELECTED: `{amount, plural, one {# commande sélectionnée} other {# commandes sélectionnées}}`,
    EMPTY_SELECTION_LIST:
      'Les commandes sélectionnées seront affichées sur la liste.',
    CANCEL_SELECTION: 'Annuler la sélection',
    NO_ORDERS_FOUND:
      "Aucune commande trouvée. Essayez avec d'autres mots-clés ou dates.",
    SHOWING_RESULTS:
      'Affichage de {results, plural, one {# résultat} other {# résultats}} pour "{search}"',
    NO_AVAILABLE_ORDERS:
      'Aucune commande disponible. Sélectionnez de nouvelles commandes pour continuer.',
    SOME_UNAVAILABLE_ORDERS:
      'Certaines des commandes sélectionnées ne sont pas disponibles.',
    ORDERS_SUCCESSFULLY_ADDED: 'Commandes ajoutées avec succès.',
    LOAD_ORDERS_ERROR: 'Impossible de charger les commandes. Réessayez.',
  },
  HIVE_ChangeDateConfirmationModal: {
    TITLE: "Changer la date de l'itinéraire?",
    DESCRIPTION:
      'Si vous changez la date de l’itinéraire, toutes les visites non attribuées seront supprimées.',
    CANCEL: 'Annuler',
    CONFIRM: 'Confirmer',
  },
};

export default {
  messages: fr,
  language: 'fr',
};
